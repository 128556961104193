import React from "react"
import { Link } from "gatsby"
import styled from "styled-components/macro"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageWrapper = styled.div`
  margin: 0 auto;
  max-width:  40ch;
  label {
    font-size: ${props => props.theme.fontSizes[1]};
  }

  p {
    margin-bottom: 2rem;
  }
  text-align: center;
`
const Subhead = styled.h2`
  text-align: center;
  font-size: ${props => props.theme.fontSizes[2]};
  margin-top: 0;
  margin-bottom: 1rem;
  @media ${props => props.theme.screenSizes.tablet} {
    font-size: ${props => props.theme.fontSizes[4]};
    margin-bottom: 2rem;
  }
`

const CTAButton = styled(Link)`
  background: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.background};
  text-shadow: none;
  font-weight: ${props => props.theme.fontWeights.black};
  font-size: ${props => props.theme.fontSizes[2]};
  line-height: 1;
  border: none;
  border-radius: 3px;
  padding: 0.75rem 1rem;
  cursor: pointer;
  text-decoration: none;
`

const ThanksPage = () => (
  <Layout>
    <SEO title="Thanks for your submission" />
    <PageWrapper>
    <Subhead>Thanks</Subhead>
    <p>Your destination has been submitted.</p>
    <p><CTAButton to="/">Return home</CTAButton></p>
    </PageWrapper>
  </Layout>
  
)

export default ThanksPage
